<template>
  <List el="xsm" style="max-width: 1200px">
    <List el="column-between xsm">
      <Text el="h2">Assignments</Text>
      <Btn
        @click="to('/dashboard/assignments/upload')"
        v-if="$store.getters.isAdmin"
        >Upload</Btn
      >
    </List>

    <List el="column-between xsm" style="align-items: center">
      <div el="tabs-underline" style="user-select: none">
        <div
          :class="{ active: videos.section == 'all' }"
          @click="changeSection('all')"
        >
          All
        </div>
        <div
          :class="{ active: videos.section == 'optional' }"
          @click="changeSection('optional')"
        >
          Optional
        </div>
        <div
          :class="{ active: videos.section == 'draft' }"
          @click="changeSection('draft')"
          v-if="$store.getters.isAdmin"
        >
          Draft
        </div>
      </div>
      <div el="input-list">
        <label el="input">
          <input
            type="text"
            placeholder="Search..."
            v-model="videos.searchInput"
            el
          />
        </label>
      </div>
    </List>

    <table el="table">
      <thead>
        <tr>
          <th width="180px">Due Date</th>
          <th>Title</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in filteredAssignments"
          :key="item"
          @click="to('/dashboard/assignments/view/' + item.id)"
          :class="{ active: $route.params.id == item.id }"
        >
          <td>{{ formatDate(item.dueDate) }}</td>
          <td>{{ item.label }}</td>
          <td>
            <div el="status-draft" v-if="formatDateStatus(item.dueDate) == 'Closed'">Closed</div>
            <div el="status-error" v-else-if="formatDateStatus(item.dueDate) == 'Due Today'">Due Today</div>
            <div el="status-warning" v-else>{{formatDateStatus(item.dueDate)}}</div>
            </td>
        </tr>
        <!-- <tr>
          <td>Lowis</td>
          <td>Lane</td>
        </tr> -->
      </tbody>
    </table>
  </List>
</template>
<script>
import router from "@/router";
let filter = require("lodash/filter");
let lowerCase = require("lodash/lowerCase");
let includes = require("lodash/includes");
let dayjs = require('dayjs');
let relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export default {
  components: {
    // Layout,
  },
  data() {
    return {
      groups: this.$store.state.main.groups,
      videos: {
        searchInput: "",
        section: "all",
        filters: {
          tag: "all",
          tags:[],
          status: "publish",
        },
      },
    };
  },
  watch:{
    "groups.active": function (val) {
      this.videos.filters.tags = [val];
    },
  },
  methods: {
    changeSection(val) {
      this.videos.section = val;
      this.videos.filters.tag = val;
      this.$store.commit("collapseSection", true);
    },
    formatDate(dateString) {
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const d = new Date(`${dateString} 00:00:00`);
      return `${dateString}, ${days[d.getDay()]}`;
    },
    formatDateStatus(dateString) {
      // const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const now = dayjs();
      const dueDate = `${dateString} 23:59:59`;
      let hrs = now.diff(dueDate, 'hour');
      let result = 'Closed';

      if(hrs < 0 && hrs >= -24 ){
        result = 'Due Today';
      } else if (hrs < 0){
        result = 'Due ' + now.to(dayjs(dueDate));
      }
      return result;
    },
    to(path) {
      this.$store.commit("collapseSection", false);
      router.push(path);
    },
  },
  computed: {
    filteredAssignments() {
      let x = this;
      let checker = (arr, target) => target.every((v) => arr.includes(v));
      return filter(this.$store.state.assignments.list, function (item) {
        if (x.videos.section === "draft") {
          return (
            item.status == "draft" &&
            lowerCase(`${item.date} ${item.label}`).search(
              lowerCase(x.videos.searchInput)
            ) > -1
          );
        } else {
          return (
            checker(item.tags, x.videos.filters.tags) == true &&
            includes(item.tags, x.videos.filters.tag) == true &&
            item.status == "publish" &&
            lowerCase(`${item.date} ${item.label}`).search(
              lowerCase(x.videos.searchInput)
            ) > -1
          );
        }
      });
    },
  },
  mounted() {
    let pathArr = this.$route.path.split("/");
    let endPath = pathArr.pop();
    if (endPath == "upload") {
      this.$store.commit("collapseSection", false);
    } else {
      this.$store.commit("collapseSection", true);
    }
    this.$store.commit("assignments/loadList");
    this.videos.filters.tags = [this.groups.active];
  },
};
</script>
<style lang="scss" scoped>

</style>
